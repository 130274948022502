import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export type ConfigState = {
	contactLink: string
}
export const initialConfigState: ConfigState = {
	contactLink: 'mailto:support@LC-saas.fr',
}

export const configSlice = createSlice({
	name: 'config',
	initialState: initialConfigState,
	reducers: {
		setContactLink: (state, action: PayloadAction<string>) => {
			state.contactLink = action.payload
		},
	},
})

export default configSlice.reducer
export const {
	actions: { setContactLink },
} = configSlice
