import { configureStore } from '@reduxjs/toolkit'
import { createElement, type ReactNode } from 'react'
import { Provider as StoreProvider } from 'react-redux'

import auth from './auth'
import config from './config'
import gen from './gen'
import instance from './instance'
import project from './project'

export const store = configureStore({
	reducer: {
		auth,
		project,
		gen,
		instance,
		config,
	},
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const Provider = ({ children }: { children: ReactNode }) => {
	// eslint-disable-next-line react/no-children-prop
	return createElement(StoreProvider, { store, children }, children)
}
